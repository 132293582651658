@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  font-family: "Poppins", sans-serif;
}

.logo {
  max-width: 120px !important;
}

#header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
#header .menu {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
#header .menu a {
  text-decoration: none;
  color: #464646;
}
.redButton {
  background: #cf2e2e;
  border: none;
  padding: 10px 15px;
  color: white;
}
.baner {
  background-position: top;
  background-image: url("../public/baner.jpg");
  min-height: 50vh;
  display: flex;
  color: white;
  flex-direction: column;
  gap: 20px;
  background-position: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  justify-content: center;
}
.baner input {
  font-size: 18px;
  width: 600px;
  max-width: 90vw;
  height: 50px;
  border-radius: 6px;
  border: none;
  padding: 4px 10px;
}
.priceFilters input {
  width: 290px;
}
.paginationTable {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.pages {
  display: flex;
  flex-direction: row;
  gap: 0;
}
.pages .numer {
  background: #fff;
  border: 1px solid #dbdfea;
  cursor: pointer;
  padding: 5px 10px;
}
.pages .numer#active {
  background: #000;
  border-color: #000;
  color: #fff;
}
.selectPage {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.selectPage input {
  height: 30px;
  width: 50px;
}
.priceFilters {
  width: 600px;
  display: flex;
  max-width: 90vw;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.baner input:focus {
  outline: none;
}

.baner h1 {
  font-size: 50px;
}
.id {
  position: absolute;
  bottom: 0;
  right: 10px;
  font-size: 14px;
}

.kafelek {
  cursor: pointer;
  position: relative;
  border-radius: 6px;
  box-shadow: 1px 1px 10px 2px rgba(99, 98, 98, 0.219);
  border: 1px solid rgba(0, 0, 0, 0.185);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#footer {
  background-color: #23252d;
  color: white;
}

#footer a {
  text-decoration: none;
  color: white;
}
.facebook {
  font-size: 30px;
}

.phone {
  position: absolute;
  top: 5px;
  font-size: 20px;
  right: 10px;
}
.kafelek .firma {
  max-width: 90%;
}
.mail {
  position: absolute;
  top: 40px;
  right: 10px;
  font-size: 20px;
}
.cena {
  color: #cf2e2e;
}

#loginPage {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.formWrapper {
  border: 1px solid #46464667;
  padding: 20px;
  border-radius: 8px;
}
.formWrapper input {
  max-width: 90vw;
  width: 400px;
}
.red {
  color: red;
  font-size: 18px;
}
.admin {
  min-height: 60vh;
}

.admin .row {
  min-height: 60vh;
}

@media only screen and (max-width: 768px) {
  #footer {
    text-align: center;
  }
  .baner h1 {
    font-size: 30px;
    text-align: center;
  }
  .kafelek {
    overflow: hidden;
  }
  .kafelek {
    word-wrap: break-word;
  }
}

.menuMobile {
  display: none;
}

.dropdown button {
  background: white !important;
  border: none !important;
}
.dropdown button svg {
  color: #cf2e2e;
  font-size: 30px;
}

.dropdown-menu a {
  text-decoration: none;
  color: black;
}
.dropdown-menu li {
  padding: 5px 10px;
}
@media only screen and (max-width: 1024px) {
  #header .menu {
    display: none;
  }
  .menuMobile {
    display: block;
  }
}
@media only screen and (max-width: 768px) {
  .priceFilters {
    flex-direction: row;
    gap: 10px;
  }
  .priceFilters input {
    max-width: 90vw;
    width: 50%;
  }
}
